<template>
  <div class="index-video">
    <transition name="fade">
      <div
        v-show="imgShow"
        class="img-box"
      >
        <img
          src="@/assets/images/new/qd@2x.png"
          alt=""
        >
      </div>
    </transition>

    <div
      v-show="videoShow"
      class="container-video"
      @click="goHome"
    >
      <video
        id="video-tvc"
        autoplay
        :muted="isMute"
        allowfullscreen="allowfullscreen"
        loop="loop"
        data-object-fit=""
        class="video-tvc"
      >
        抱歉，您的浏览器不支持内嵌视频
        <source
          type="video/mp4"
          src="@/assets/video/start.mp4"
        >
      </video>

      <div
        class="home-btn"
        @click="goHome"
      >
        <img
          src="@/assets/images/new/jr@2x.png"
          alt
        >
        进入首页
      </div>

      <div
        class="mute-icon"
        @click.stop="muteClick"
      >
        <img
          v-if="isMute"
          src="@/assets/images/new/video1.png"
          alt=""
        >
        <img
          v-else
          src="@/assets/images/new/video2.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import cookie from '@/utils/cookies'
import { ElMessage, ElMessageBox } from 'element-plus'
const imgShow = ref(true)
const videoShow = ref(false)
const router = useRouter()
const isMute = ref(true)
const goHome = () => {
  router.push('/home')
}

const cookieData = cookie.getShop()
useHead({
  link: [
    { rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon || '' }
  ],
  title: cookieData.shopName || ''
})

const muteClick = () => {
  isMute.value = !isMute.value

  // if (process.client) {
  //   let video = document.getElementById('video-tvc')
  // }
}
setTimeout(() => {
  imgShow.value = false
}, 3000)
setTimeout(() => {
  videoShow.value = true
}, 3000)
// 获取当前登录的浏览器
const getLogoinBrowser = () => {
  const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  console.log('loginuserAgent:', userAgent)
  // 判断是否Opera浏览器
  if (userAgent.indexOf('Opera') > -1) {
    return 'Opera'
  }

  // 判断是否Edge浏览器
  if (userAgent.indexOf('Edg') > -1) {
    return 'Edge'
  }

  // 判断是否Firefox浏览器
  if (userAgent.indexOf('Firefox') > -1) {
    return 'firefox'
  }

  // 判断是否Chrome浏览器
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome'
  }

  // 判断是否Safari浏览器
  if (userAgent.indexOf('Safari') > -1) {
    return 'Safari'
  }

  // 判断是否IE浏览器
  if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
    return 'IE'
  }
  if (userAgent.indexOf('Trident') > -1) {
    return 'IE'
  }
}
onMounted(() => {
  // const Browser = getLogoinBrowser()
  // console.log(Browser)
  // if (Browser !== 'Chrome' && Browser !== 'Firefox' && Browser !== 'Edge') {
  //   ElMessageBox.alert('抱歉!暂时还无法兼容您当前的浏览器!为了您能正常使用网址功能推荐使用谷歌浏览器，火狐浏览器，360浏览器(极速模式），Edge浏览器', '提示', {
  //   // if you want to disable its autofocus
  //   // autofocus: false,
  //     confirmButtonText: '确定'

  //   })
  // }
})
</script>
<style lang="scss" scoped>

.mute-icon{
  position: absolute;
  right: 10vw;
  top: 10vh;
  img{
    width: 31px;
    height: 30px;
    opacity: 0.5;
    cursor: pointer;

    &:hover{
      opacity: 1;
    }
  }

}

.fade-leave,   // 离开前,进入后透明度是1
.fade-enter-to {
  opacity: 1;
}
.fade-leave-active,
.fade-enter-active {
  transition: all 1.5s; //过度是2秒
}
.fade-leave-to,
.fade-enter {
  opacity: 0;
}

.index-video{
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .img-box{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  .container-video{

    .video-tvc{
      object-fit: cover;
      position: absolute;
      //vertical-align: middle;
      //top: 50%;
      //-webkit-transform: translate(-50%, -50%);
      //transform: translate(-50%, -50%);
      //left: 50%;

      vertical-align: middle;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .home-btn{
      position: absolute;
      bottom: 156px;
      left: calc( 50% - 142px );
      background: rgba(55,55,55,0.74);
      border-radius: 31.5px;
      width: 284px;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      font-size: 30px;
      img{
        height: 31px;
        width: 31px;
        margin: 0 20px;
      }
    }
  }
}
</style>
